import { AxiosPromise, AxiosResponse } from "axios";
import { ActionContext, Module } from "vuex";

import createApi from "./fly-order.api";
import actionHelper from "utils/actionHelper";
import {
  AsyncCallStatus,
  FlightPaxDetails,
  FlightServiceDetailsResource,
  FlightServicesResource,
  OrderItemResource
} from "tl-api-doc/typescript-generator";
import {
  IAgencyLogo,
  IBeginOrderResponse,
  ICustomer,
  ICustomerSeats,
  IFlyOrderState,
  IFrequentFlyer,
  IPaxTypeInformation,
  IResultProductPrice,
  ISavedServices,
  TicketingAllowanceStatus
} from "./types/fly-order.types";
import Vue from "vue";
import isEqual from "lodash/isEqual";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import { CompanyType } from "tl-api-doc/typescript-generator/assembly";
import { RootState } from "../../applications/desktop/store";

const BEGIN_ORDER = actionHelper("BEGIN_ORDER");
const PRODUCT_TO_ORDER = actionHelper("PRODUCT_TO_ORDER");
const FIND_COUNTRY = actionHelper("FIND_COUNTRY");
const GET_AGENCY_CUSTOMERS = actionHelper("GET_AGENCY_CUSTOMERS");
const REGISTRATION_NEW_CUSTOMER = actionHelper("REGISTRATION_NEW_CUSTOMER");
const UPDATE_CUSTOMER = actionHelper("UPDATE_CUSTOMER");
const ADD_CUSTOMER_TO_ORDER = actionHelper("ADD_CUSTOMER_TO_ORDER");
const REMOVE_CUSTOMER_FROM_ORDER = actionHelper("REMOVE_CUSTOMER_FROM_ORDER");
const START_ORDER_RESERVATION = actionHelper("START_ORDER_RESERVATION");
const GET_ORDER_PRERESERVATION_STATUS = actionHelper(
  "GET_ORDER_PRERESERVATION_STATUS"
);
const GET_SPECIAL_ASSISTANCE = actionHelper("GET_SPECIAL_ASSISTANCE");
const GET_RESERVATION_STATUS = actionHelper("GET_RESERVATION_STATUS");
const GET_CONFIRMATION_STATUS = actionHelper("GET_CONFIRMATION_STATUS");
const GET_PRODUCT_PRICE = actionHelper("GET_PRODUCT_PRICE");
const GET_FLIGHT_ORDER_ITEMS = actionHelper("GET_FLIGHT_ORDER_ITEMS");
const GET_DRAFT_ORDER = actionHelper("GET_DRAFT_ORDER");
const GET_AGENCY_LOGO = actionHelper("GET_AGENCY_LOGO");
const GET_PAYMENT_URL = actionHelper("GET_PAYMENT_URL");
const GET_PAX_TYPE = actionHelper("GET_PAX_TYPE");
const SET_MARKUP_TO_ORDER = actionHelper("SET_MARKUP_TO_ORDER");
const UPDATE_MARKUP_TO_ORDER = actionHelper("UPDATE_MARKUP_TO_ORDER");

const SET_ORDER_ERROR = "SET_ORDER_ERROR";
const SET_ORDER_PENDING = "SET_ORDER_PENDING";
const INPUT_CUSTOMERS = "INPUT_CUSTOMERS";
const INPUT_ALL_CUSTOMERS = "INPUT_ALL_CUSTOMERS";
const INPUT_OWNER = "INPUT_OWNER";
const INPUT_ASSISTANCE_PENDING = "INPUT_ASSISTANCE_PENDING";
const SET_ORDER_ITEM = "SET_ORDER_ITEM";
const SET_FREQUENT_FLYERS = "SET_FREQUENT_FLYERS";
const SET_SEAT_MAP_SEGMENTS = actionHelper("SET_SEAT_MAP_SEGMENTS");
const SET_SERVICES_SEGMENTS = actionHelper("SET_SERVICES_SEGMENTS");
const SAVE_SEATS = "SAVE_SEATS";
const SAVE_ALL_SEATS = "SAVE_ALL_SEATS";
const SAVE_SERVICES = "SAVE_SERVICES";
const RESET_STORE = "RESET_STORE";
const CLEAR_PAYMENT_URL = "CLEAR_PAYMENT_URL";
const SERVICE_RESERVATION = actionHelper("SERVICE_RESERVATION");
const SERVICE_FLIGHT_DETAILS = actionHelper("SERVICE_FLIGHT_DETAILS");
const TRAVEL_ORDER_RESOURCE = actionHelper("TRAVEL_ORDER_RESOURCE");
const GET_ISSUE_TICKET_STATUS = actionHelper("GET_ISSUE_TICKET_STATUS");
const GET_TICKET_ALLOWANCE = actionHelper("GET_TICKET_ALLOWANCE");

const SET_REMARKS = actionHelper("SET_REMARKS");

export default ({
  api
}: {
    api: ReturnType<typeof createApi>;
}): Module<IFlyOrderState, RootState> => ({
  namespaced: true,

  state: {
    ticketingAllowancePending: false,
    ticketingAllowanceStatus: null,
    issueStatusPending: false,
    issueStatus: null,
    agencyCustomers: [],
    agencyCustomersPending: false,
    agencyLogoPending: true,
    agencyLogoUrl: null,
    bookedProduct: null,
    bookedProductPrice: null,
    countries: [],
    customers: [],
    error: null,
    frequentFlyers: [],
    isAssistancePending: false,
    isCountryPending: false,
    isLoadingPending: false,
    isOrderPending: false,
    isPricePending: false,
    isSeatSegmentsError: null,
    isSeatSegmentsPending: false,
    isServiceFlightReservationError: null,
    isServiceReservationError: null,
    isServiceSegmentsError: null,
    isServiceSegmentsPending: false,
    itemId: null,
    order: null,
    orderId: null,
    orderNumber: null,
    orderPrereservationStatus: null,
    orderStatus: null,
    owner: [],
    paymentUrl: null,
    pnrNumber: null,
    prevBookedProductPrice: null,
    savedSeats: [],
    savedServices: {
      segments: {}
    },
    seatSegments: null,
    serviceSegments: null,
    travelOrderResource: null,
    travelOrderResourceError: null,
    travelOrderResourcePending: false,
    conformationStatusPending: false,
    conformationStatus: null,
    typesOfAssistance: {
      assistances: [],
      meals: []
    },
    remarksError: null,
    customerDOBrule: null
  },

  getters: {
    customerDOBrule: (state: IFlyOrderState) => state.customerDOBrule,
    agencyCustomers: (state: IFlyOrderState) => state.agencyCustomers,
    agencyCustomersPending: (state: IFlyOrderState) =>
      state.agencyCustomersPending,
    agencyLogoPending: (state: IFlyOrderState) => state.agencyLogoPending,
    agencyLogoUrl: (state: IFlyOrderState) => state.agencyLogoUrl,
    bookedProduct: (state: IFlyOrderState) => state.bookedProduct,
    bookedProductPrice: (state: IFlyOrderState) => state.bookedProductPrice,
    countries: (state: IFlyOrderState) => state.countries,
    customers: (state: IFlyOrderState) => state.customers,
    error: (state: IFlyOrderState) => state.error,
    frequentFlyers: (state: IFlyOrderState) => state.frequentFlyers,
    isAssistancePending: (state: IFlyOrderState) => state.isAssistancePending,
    isCountryPending: (state: IFlyOrderState) => state.isCountryPending,
    isOrderPending: (state: IFlyOrderState) => state.isOrderPending,
    isPricePending: (state: IFlyOrderState) => state.isPricePending,
    isSeatSegmentsError: (state: IFlyOrderState) => state.isSeatSegmentsError,
    isSeatSegmentsPending: (state: IFlyOrderState) =>
      state.isSeatSegmentsPending,
    isServiceSegmentsError: (state: IFlyOrderState) =>
      state.isServiceSegmentsError,
    isServiceSegmentsPending: (state: IFlyOrderState) =>
      state.isServiceSegmentsPending,
    itemId: (state: IFlyOrderState) => state.itemId,
    order: (state: IFlyOrderState) => state.order,
    orderId: (state: IFlyOrderState) => state.orderId,
    orderNumber: (state: IFlyOrderState) => state.orderNumber,
    orderPrereservationStatus: (state: IFlyOrderState) =>
      state.orderPrereservationStatus,
    orderStatus: (state: IFlyOrderState) => state.orderStatus,
    owner: (state: IFlyOrderState) => state.owner,
    paymentUrl: (state: IFlyOrderState) => state.paymentUrl,
    pnrNumber: (state: IFlyOrderState) => state.pnrNumber,
    prevBookedProductPrice: (state: IFlyOrderState) =>
      state.prevBookedProductPrice,
    savedSeats: (state: IFlyOrderState) => state.savedSeats,
    savedServices: (state: IFlyOrderState) => state.savedServices,
    seatSegments: (state: IFlyOrderState) => state.seatSegments,
    serviceSegments: (state: IFlyOrderState) => state.serviceSegments,
    travelOrderResource: (state: IFlyOrderState) => state.travelOrderResource,
    travelOrderResourceError: (state: IFlyOrderState) =>
      state.travelOrderResourceError,
    travelOrderResourcePending: (state: IFlyOrderState) =>
      state.travelOrderResourcePending,
    typesOfAssistance: (state: IFlyOrderState) => state.typesOfAssistance,
    issueStatusPending: (
      state: IFlyOrderState
    ): IFlyOrderState["issueStatusPending"] => state.issueStatusPending,
    issueStatus: (state: IFlyOrderState): IFlyOrderState["issueStatus"] =>
      state.issueStatus,
    ticketingAllowancePending: (
      state: IFlyOrderState
    ): IFlyOrderState["ticketingAllowancePending"] =>
      state.ticketingAllowancePending,
    ticketingAllowanceStatus: (
      state: IFlyOrderState
    ): IFlyOrderState["ticketingAllowanceStatus"] =>
      state.ticketingAllowanceStatus
  },

  actions: {
    async setMarkupToOrder(
      { commit },
      {
        orderId,
        orderItemId,
        markup
      }: {
                orderId: string;
                orderItemId: string;
                markup: number;
            }
    ) {
      // commit(SET_MARKUP_TO_ORDER.INITIAL);
      try {
        await api.setMarkupToOrder({
          orderId,
          orderItemId,
          markup
        });
        // commit(SET_MARKUP_TO_ORDER.SUCCEEDED);
        return Promise.resolve();
      } catch (e) {
        commit(SET_MARKUP_TO_ORDER.FAILED, e);
        return Promise.reject();
      }
    },

    async updateMarkupToOrder(
      { commit },
      {
        orderId,
        orderItemId,
        markup,
        uuid
      }: {
                orderId: string;
                orderItemId: string;
                markup: number;
                uuid: string;
            }
    ) {
      // commit(UPDATE_MARKUP_TO_ORDER.INITIAL);
      try {
        await api.updateMarkupToOrder({
          orderId,
          orderItemId,
          markup,
          uuid
        });
        // commit(UPDATE_MARKUP_TO_ORDER.SUCCEEDED);
        return Promise.resolve();
      } catch (e) {
        commit(UPDATE_MARKUP_TO_ORDER.FAILED, e);
        return Promise.reject();
      }
    },
    async getPaxType(
      { commit },
      {
        itemId,
        birthday
      }: {
                itemId: string;
                birthday: string
            }
    ) {
      try {
        const getPaxType: AxiosResponse<IPaxTypeInformation> =
                    await api.getPaxType({
                      itemId,
                      birthday
                    });
        commit(GET_PAX_TYPE.SUCCEEDED, getPaxType.data);
        return Promise.resolve();
      } catch (e) {
        commit(GET_PAX_TYPE.FAILED, e);
        return Promise.reject();
      }
    },
    async reBuildOrder({ commit }, oderId: string) {
      commit("SET_ODER_ID", oderId);
    },
    async beginOrder(
      { commit },
      {
        productPrice,
        uuid
      }: {
                productPrice: IResultProductPrice;
                uuid: string
            }
    ) {
      try {
        const beginOrder: AxiosResponse<IBeginOrderResponse> =
                    await api.beginOrder({
                      agencyId: productPrice.agency.agencyId,
                      uuid
                    });

        commit(BEGIN_ORDER.SUCCEEDED, beginOrder.data);

        return beginOrder.data.entityId;
      } catch (error) {
        commit(BEGIN_ORDER.FAILED, error);
      }
    },
    async addProductToOrder(
      { commit },
      {
        orderId,
        productId
      }: {
                orderId: string;
                productId: string
            }
    ) {
      try {
        const result = await api.addProductToOrder(orderId, productId);
        commit(
          PRODUCT_TO_ORDER.SUCCEEDED,
          result
        );
        return result.data;
      } catch (error) {
        commit(PRODUCT_TO_ORDER.FAILED, error);
      }
    },
    async getAgencyLogo({ commit }, params: IAgencyLogo): Promise<void> {
      try {
        commit(
          GET_AGENCY_LOGO.SUCCEEDED,
          await api.getAgencyLogo(params.agencyId, params.size)
        );
      } catch (e) {
        commit(GET_AGENCY_LOGO.FAILED, e);
      }
    },
    async registrationNewCustomer(
      { commit },
      {
        orderId,
        customer,
        customerIndex,
        isOwner
      }: {
                orderId: string;
                customer: ICustomer;
                customerIndex: number;
                isOwner: boolean;
            }
    ) {
      try {
        if (
          customer.addresses &&
                    customer.addresses[0]?.country &&
                    typeof customer.addresses[0].country !== "string"
        ) {
          customer = {
            ...customer,
            addresses: [
              {
                ...customer.addresses[0],

                country: customer.addresses[0].country.code
              }
            ]
          };
        }
        const result = await api.registrationNewCustomer(orderId, customer);

        commit(REGISTRATION_NEW_CUSTOMER.SUCCEEDED, {
          customerIndex,
          isOwner,
          result
        });
      } catch (error) {
        commit(REGISTRATION_NEW_CUSTOMER.FAILED, error);
      }
    },
    async updateCustomer(
      { commit },
      {
        orderId,
        customer
      }: {
                orderId: string;
                customer: ICustomer;
            }
    ) {
      try {
        commit(UPDATE_CUSTOMER.INITIAL);

        await api.updateCustomerInOrder(orderId, customer);
      } catch (e) {
        commit(UPDATE_CUSTOMER.FAILED, e);
      }
    },
    async addCustomerToOrder(
      { commit },
      {
        orderId,
        itemId,
        customerId,
        isOwner
      }: {
                orderId: string;
                itemId: string;
                customerId: string;
                isOwner: boolean;
            }
    ) {
      try {
        commit(
          ADD_CUSTOMER_TO_ORDER.SUCCEEDED,
          await api.addCustomerToOrder(orderId, itemId, customerId, isOwner)
        );
      } catch (error) {
        // console.log('err')
      }
    },
    async removeCustomerFromOrder({ commit }, {
      orderId,
      customerId
    }: { orderId: string, customerId: string }) {
      commit(REMOVE_CUSTOMER_FROM_ORDER.INITIAL);
      try {
        const result = await api.removeCustomerFromOrder(orderId, customerId);
        commit(
          REMOVE_CUSTOMER_FROM_ORDER.SUCCEEDED,
          result
        );
        return result;
      } catch (error) {
        commit(REMOVE_CUSTOMER_FROM_ORDER.FAILED, error);
        return Promise.reject(error);
      }
    },

    async getSpecialAssistance(
      { commit },
      {
        orderId,
        itemId,
        customerId,
        type
      }: {
                orderId: string;
                itemId: string;
                customerId: string;
                type: string;
            }
    ) {
      try {
        commit(GET_SPECIAL_ASSISTANCE.SUCCEEDED, {
          result: await api.getSpecialAssistance(
            orderId,
            itemId,
            customerId,
            type
          ),
          type
        });
      } catch (error) {
        commit(GET_SPECIAL_ASSISTANCE.FAILED, error);
        // console.log('err')
      }
    },
    async getFlightOrderItems({ commit }, orderId) {
      try {
        commit(
          GET_FLIGHT_ORDER_ITEMS.SUCCEEDED,
          await api.getFlightOrderItems(orderId)
        );
        return Promise.resolve();
      } catch (error) {
        commit(GET_FLIGHT_ORDER_ITEMS.FAILED, error);
      }
    },
    async getDraftOrder({ commit }, orderId) {
      try {
        commit(
          GET_DRAFT_ORDER.SUCCEEDED,
          await api.getFlightOrderItems(orderId)
        );
      } catch (error) {
        commit(GET_DRAFT_ORDER.FAILED, error);
      }
    },
    setSpecialAssistance(
      { commit },
      {
        orderId,
        itemId,
        customerId,
        customerAssistance
      }: {
                orderId: string;
                itemId: string;
                customerId: string;
                customerAssistance: {
                    meal?: string;
                    specialAssistance?: string;
                    frequentFlyerCards?: IFrequentFlyer[];
                };
            }
    ) {
      api.setSpecialAssistance(orderId, itemId, customerId, customerAssistance);
    },
    async getOrderPreReservationStatus(
      { commit },
      orderId: string
    ): Promise<{
            status: AsyncCallStatus
        }> {
      try {
        if (!orderId) return { status: "ERROR" };
        const preReservationResponse: AxiosResponse<{
                    status: AsyncCallStatus;
                }> = await api.startOrderPreReservation(orderId);
        commit(
          GET_ORDER_PRERESERVATION_STATUS.SUCCEEDED,
          preReservationResponse
        );
        return preReservationResponse.data;
      } catch (error) {
        commit(GET_ORDER_PRERESERVATION_STATUS.FAILED, error);
      }
    },
    async getAvailableBalance({ commit }, { uuid }: {
            uuid: string
        }) {
      return api.getAvailableBalance(uuid);
    },

    async startOrderReservation(
      { commit },
      {
        orderId,
        onlinePayment
      }: {
                orderId: string;
                onlinePayment: boolean
            }
    ) {
      try {
        const reservation = await api.startOrderReservation(
          orderId,
          onlinePayment
        );

        commit(START_ORDER_RESERVATION.SUCCEEDED, reservation);
        return reservation;
      } catch (error) {
        commit(START_ORDER_RESERVATION.FAILED, error);
        return Promise.reject(error);
      }
    },
    async getReservationStatus({ commit }, orderId) {
      try {
        commit(
          GET_RESERVATION_STATUS.SUCCEEDED,
          await api.getReservationStatus(orderId)
        );
      } catch (error) {
        commit(GET_RESERVATION_STATUS.FAILED, error);
        console.error(error);
        return Promise.reject(error);
      }
    },
    async getConfirmationStatus({ commit }, orderId) {
      commit(GET_CONFIRMATION_STATUS.INITIAL);
      try {
        const confStatus = (
          await (api.getConfirmationStatus(orderId) as AxiosPromise<{
                        status: "OK" | "AWAIT" | "ERROR";
                    }>)
        ).data;
        commit(GET_CONFIRMATION_STATUS.SUCCEEDED, confStatus);
        return confStatus;
      } catch (error) {
        commit(GET_CONFIRMATION_STATUS.FAILED, error);
        console.error(error);
        return Promise.reject(error);
      }
    },

    async askTicketingAllowance(
      {
        commit,
        dispatch
      },
      {
        uuid,
        orderId,
        orderItemId
      }: {
                uuid: string;
                orderId: string;
                orderItemId: string;
            }
    ) {
      commit(GET_TICKET_ALLOWANCE.INITIAL);
      try {
        if (!uuid) {
          throw new Error("empty uuid");
        }
        const ticketingAllowance: TicketingAllowanceStatus = (
          await api.ticketingAllowance({
            uuid,
            orderId,
            orderItemId
          })
        ).data;
        commit(GET_TICKET_ALLOWANCE.SUCCEEDED, ticketingAllowance);
        return ticketingAllowance;
      } catch (e) {
        commit(GET_TICKET_ALLOWANCE.FAILED, e);
        console.error(e);
      }
    },

    async issueTicket(
      {
        commit,
        dispatch
      },
      {
        uuid,
        agencyId,
        orderId,
        orderItemId,
        companyType
      }: {
                uuid: string;
                agencyId: string;
                orderId: string;
                orderItemId: string;
                companyType: CompanyType;
            }
    ) {
      commit(GET_ISSUE_TICKET_STATUS.INITIAL);
      try {
        let issueRequestPromise: AxiosPromise<any>;
        if (companyType === "AGENCY") {
          issueRequestPromise = api.agentIssueTickets({
            uuid,
            orderId,
            orderItemId
          });
        } else if (companyType === "BACKOFFICE") {
          issueRequestPromise = api.backofficeIssueTickets({
            agencyId,
            uuid,
            orderId,
            orderItemId
          });
        } else {
          commit(GET_ISSUE_TICKET_STATUS.FAILED, companyType);
          return Promise.reject();
        }
        if (issueRequestPromise) {
          issueRequestPromise = (await issueRequestPromise).data;
          commit(GET_ISSUE_TICKET_STATUS.SUCCEEDED, issueRequestPromise);
          return issueRequestPromise;
        }
      } catch (e) {
        commit(GET_ISSUE_TICKET_STATUS.FAILED, e);
        return Promise.reject();
      }
    },
    async findCountry(
      { commit }: ActionContext<IFlyOrderState, RootState>,
      request: {
                term: string;
                locale: string
            }
    ) {
      commit(FIND_COUNTRY.INITIAL);
      try {
        commit(FIND_COUNTRY.SUCCEEDED, await api.findCountry(request));
      } catch (error) {
        commit(FIND_COUNTRY.FAILED, error);
      }
    },
    async getAgencyCustomers(
      { commit },
      {
        uuid,
        name
      }: {
                uuid: string;
                name: string
            }
    ) {
      commit(GET_AGENCY_CUSTOMERS.INITIAL);
      try {
        commit(
          GET_AGENCY_CUSTOMERS.SUCCEEDED,
          await api.getAgencyCustomers(uuid, name)
        );
      } catch (error) {
        commit(GET_AGENCY_CUSTOMERS.FAILED, error);
      }
    },
    async getProductPrice({ commit }, {
      searchId,
      productId
    }) {
      commit(GET_PRODUCT_PRICE.INITIAL);
      try {
        commit(
          GET_PRODUCT_PRICE.SUCCEEDED,
          await api.getProductPrice(searchId, productId)
        );
        return Promise.resolve();
      } catch (error) {
        commit(GET_PRODUCT_PRICE.FAILED, error);
        return Promise.reject(error);
      }
    },
    async getSeatSegments({ commit }, {
      orderId,
      itemId,
      locale
    }) {
      commit(SET_SEAT_MAP_SEGMENTS.INITIAL);
      try {
        commit(
          SET_SEAT_MAP_SEGMENTS.SUCCEEDED,
          await api.getSeatSegments({
            orderId,
            itemId,
            option: "seatmap",
            locale
          })
        );
      } catch (error) {
        commit(SET_SEAT_MAP_SEGMENTS.FAILED, error);
      }
    },
    async getServiceSegments({ commit }, {
      orderId,
      itemId,
      locale
    }) {
      commit(SET_SERVICES_SEGMENTS.INITIAL);
      try {
        commit(
          SET_SERVICES_SEGMENTS.SUCCEEDED,
          await api.getSeatSegments({
            orderId,
            itemId,
            option: "services",
            locale
          })
        );
      } catch (error) {
        commit(SET_SERVICES_SEGMENTS.FAILED, error);
      }
    },
    async serviceReservation(
      { commit },
      {
        orderId,
        itemId,
        customerId,
        query
      }
    ) {
      commit(SERVICE_RESERVATION.INITIAL);
      try {
        commit(
          SERVICE_RESERVATION.SUCCEEDED,
          await api.serviceReservation(orderId, itemId, customerId, query)
        );
      } catch (error) {
        commit(SERVICE_RESERVATION.FAILED, error);
      }
    },
    async serviceFlightDetails(
      { commit },
      {
        orderId,
        itemId,
        customerId,
        details
      }: {
                orderId: string;
                itemId: string;
                customerId: string;
                details: FlightPaxDetails;
            }
    ) {
      commit(SERVICE_FLIGHT_DETAILS.INITIAL);
      try {
        commit(
          SERVICE_FLIGHT_DETAILS.SUCCEEDED,
          await api.serviceFlightDetails(orderId, itemId, customerId, details)
        );
      } catch (error) {
        commit(SERVICE_FLIGHT_DETAILS.FAILED, error);
      }
    },
    async getTravelOrderResource({ commit }, { orderId }) {
      commit(TRAVEL_ORDER_RESOURCE.INITIAL);
      try {
        const result = await api.travelOrderResource(orderId);
        commit(TRAVEL_ORDER_RESOURCE.SUCCEEDED, result);
        return result;
      } catch (error) {
        console.warn(error);
        commit(TRAVEL_ORDER_RESOURCE.FAILED, error);
        return Promise.reject(error);
      }
    },
    async sendOrderPdf({ commit }, query: {
            documentFileName: string;
            orderId: string;
            locale: string;
            isMail: boolean;
            body: string;
            subject: string;
            mailsTo: string | string[];
            includePrice: boolean;
            type: string;
            pdfTitle: string
            uuid: string;
        }) {
      return api.sendOrderPdf(query);
    },
    async setRemarks(
      ctx: ActionContext<IFlyOrderState, RootState>,
      params: {
                orderItemId: string;
                remark1: string;
                remark2: string;
                uuid: string;
            }
    ) {
      const { commit } = ctx;
      commit(SET_REMARKS.INITIAL);

      try {
        await api.setRemarks(params);
        commit(SET_REMARKS.SUCCEEDED);
        return Promise.resolve();
      } catch (e) {
        commit(SET_REMARKS.FAILED, e);
        return Promise.reject(e);
      }
    }
  },

  mutations: {
    [RESET_STORE](state) {
      state.bookedProduct = null;
      state.bookedProductPrice = null;
      state.countries = [];
      state.owner = [];
      state.customers = [];
      state.isOrderPending = false;
      state.isPricePending = false;
      state.isSeatSegmentsError = null;
      state.isSeatSegmentsPending = false;
      state.isServiceReservationError = null;
      state.isServiceSegmentsError = null;
      state.isServiceSegmentsPending = false;
      state.serviceSegments = null;
      state.orderPrereservationStatus = null;
      state.savedSeats = [];
      state.savedServices = {
        segments: {}
      };
      state.typesOfAssistance = {
        assistances: [],
        meals: []
      };
      state.customerDOBrule = null;
    },
    [SET_FREQUENT_FLYERS](state, flyersObj) {
      const target = state.frequentFlyers.find(
        (item) => item.index === flyersObj.index
      );
      if (target) {
        target.flyers = flyersObj.flyers;
      } else {
        state.frequentFlyers.push({
          flyers: flyersObj.flyers,
          index: flyersObj.index
        });
      }
    },
    [GET_AGENCY_LOGO.SUCCEEDED](state, response) {
      state.agencyLogoPending = false;
      state.agencyLogoUrl = window.URL.createObjectURL(response.data);
    },
    [GET_AGENCY_LOGO.FAILED](state) {
      state.agencyLogoPending = false;
    },
    [GET_PAX_TYPE.SUCCEEDED](state, payload: IPaxTypeInformation) {
      state.customerDOBrule = payload;
    },
    [GET_PAX_TYPE.FAILED](state, error) {
      state.customerDOBrule = null;
      console.error(error);
    },
    [SET_MARKUP_TO_ORDER.INITIAL](state) {
      // empty IDK that we need here
    },
    [SET_MARKUP_TO_ORDER.SUCCEEDED](state) {
      // empty IDK that we need here
    },
    [SET_MARKUP_TO_ORDER.FAILED](state, error) {
      console.error(error);
    },
    [UPDATE_MARKUP_TO_ORDER.INITIAL](state) {
      // empty IDK that we need here
    },
    [UPDATE_MARKUP_TO_ORDER.SUCCEEDED](state) {
      // empty IDK that we need here
    },
    [UPDATE_MARKUP_TO_ORDER.FAILED](state, error) {
      console.error(error);
    },
    [SET_ORDER_ITEM](state, orderItem) {
      state.bookedProduct = orderItem.bookedProduct;
      state.bookedProductPrice = orderItem.bookedProductPrice;
      if (orderItem.itemId) {
        state.itemId = orderItem.itemId;
      }
    },
    [INPUT_OWNER](state, ownerData: ICustomer) {
      state.owner = [...state.owner];
      state.owner[0] = ownerData;
    },
    [SET_ORDER_PENDING](state, isPending) {
      state.isOrderPending = isPending;
    },
    [SET_ORDER_ERROR](state, error) {
      state.error = error;
    },
    [GET_PRODUCT_PRICE.INITIAL](state) {
      state.isPricePending = true;
      state.prevBookedProductPrice = { ...state.bookedProductPrice };
    },
    [GET_PRODUCT_PRICE.SUCCEEDED](state, priceItem) {
      state.isPricePending = false;
      state.bookedProductPrice = priceItem.data.find(
        (item: IResultProductPrice) => {
          return (
            item.productId === state.bookedProduct.productId &&
                        item.agency.agencyId === state.bookedProductPrice.agency.agencyId
          );
        }
      );
    },
    [GET_PRODUCT_PRICE.FAILED](state) {
      state.isPricePending = false;
    },
    [SET_SEAT_MAP_SEGMENTS.INITIAL](state) {
      state.isSeatSegmentsPending = true;
      state.isSeatSegmentsError = null;
      state.seatSegments = null;
    },
    [SET_SEAT_MAP_SEGMENTS.SUCCEEDED](state, segments) {
      state.isSeatSegmentsPending = false;
      state.seatSegments = segments.data;
    },
    [SET_SEAT_MAP_SEGMENTS.FAILED](state, error) {
      state.isSeatSegmentsPending = false;
      state.isSeatSegmentsError = error;
    },
    [SET_SERVICES_SEGMENTS.INITIAL](state) {
      state.isServiceSegmentsPending = true;
      state.isServiceSegmentsError = null;
      state.serviceSegments = null;
    },
    [SET_SERVICES_SEGMENTS.SUCCEEDED](state, segments) {
      state.isServiceSegmentsPending = false;
      const rawData: FlightServicesResource = segments.data;
      const newServices = rawData.services.reduce(
        (
          total: FlightServiceDetailsResource[],
          service: FlightServiceDetailsResource
        ) => {
          if (
            !total.find(
              (exService) =>
                exService.ssrCode.code === service.ssrCode.code &&
                                exService.ssrCode.name === service.ssrCode.name &&
                                exService.amount.amount === service.amount.amount &&
                                exService.passengerPaxType === service.passengerPaxType &&
                                isEqual(exService.segmentIds, service.segmentIds)
            )
          ) {
            total.push(service);
          }
          return total;
        },
        []
      );
      const newSupportedServices = rawData.supportedServices.reduce(
        (
          total: FlightServiceDetailsResource[],
          service: FlightServiceDetailsResource
        ) => {
          if (
            !total.find(
              (exService) =>
                exService.ssrCode.code === service.ssrCode.code &&
                                exService.ssrCode.name === service.ssrCode.name &&
                                exService.passengerPaxType === service.passengerPaxType &&
                                exService.amount.amount === service.amount.amount &&
                                isEqual(exService.segmentIds, service.segmentIds)
            )
          ) {
            total.push(service);
          }
          return total;
        },
        []
      );
      state.serviceSegments = {
        ...rawData,
        services: newServices,
        supportedServices: newSupportedServices
      };
    },
    [SET_SERVICES_SEGMENTS.FAILED](state, error) {
      state.isServiceSegmentsPending = false;
      state.isServiceSegmentsError = error;
    },
    [SAVE_SEATS](state, {
      paxNumber,
      seats
    }: {
            paxNumber: number,
            seats: ICustomerSeats
        }) {
      state.savedSeats = [...state.savedSeats];
      state.savedSeats[paxNumber] = seats;
    },
    [SAVE_ALL_SEATS](state, seats: ICustomerSeats[]) {
      state.savedSeats = seats;
    },
    [SAVE_SERVICES](state, segments: ISavedServices) {
      state.savedServices = segments;
    },
    [INPUT_CUSTOMERS](state, {
      customerData,
      index
    }: {
            customerData: ICustomer,
            index: number
        }) {
      Vue.set(state.customers, index, customerData);
    },
    [INPUT_ALL_CUSTOMERS](state, customers: ICustomer[]) {
      state.customers = customers;
    },
    [INPUT_ASSISTANCE_PENDING](state, isPending: boolean) {
      state.isAssistancePending = isPending;
    },
    [FIND_COUNTRY.INITIAL](state) {
      state.isCountryPending = true;
    },
    [FIND_COUNTRY.SUCCEEDED](state, response: any) {
      state.countries = response.data;
      state.isCountryPending = false;
    },
    [FIND_COUNTRY.FAILED](state, error) {
      state.error = {
        code: error.status,
        type: "find-country"
      };
      state.isCountryPending = false;
    },
    [BEGIN_ORDER.SUCCEEDED](state, data: IBeginOrderResponse) {
      state.customers = [];
      state.orderId = data.entityId;
    },
    SET_ODER_ID(state, orderId: string) {
      state.orderId = orderId;
    },
    [BEGIN_ORDER.FAILED](state, error) {
      state.error = {
        code: error.status,
        type: "order-begin"
      };
      state.isOrderPending = false;
    },
    [PRODUCT_TO_ORDER.SUCCEEDED](state, response) {
      let itemId;
      if (response.data && isArray(response.data)) {
        itemId = response.data[0].entityId;
      } else if (response.data && isObject(response.data)) {
        itemId = response.data.entityId;
      } else {
        throw new Error("bad response from product");
      }
      state.itemId = itemId;
    },
    [PRODUCT_TO_ORDER.FAILED](state, error) {
      state.error = {
        code: error.status,
        type: "product-to-order"
      };
      state.isOrderPending = false;
    },
    [REGISTRATION_NEW_CUSTOMER.SUCCEEDED](
      state,
      {
        isOwner,
        customerIndex,
        result
      }: {
                isOwner: boolean;
                customerIndex: number;
                result: any
            }
    ) {
      const resultCustomerData: ICustomer = result.data;

      if (isOwner) {
        const customer = state.owner[customerIndex] ?? resultCustomerData;
        state.owner[customerIndex] = {
          ...customer,
          customerId: resultCustomerData.customerId
        };
      } else {
        const customer = state.customers[customerIndex] ?? resultCustomerData;
        state.customers[customerIndex] = {
          ...customer,
          customerId: resultCustomerData.customerId
        };
      }
    },
    [REGISTRATION_NEW_CUSTOMER.FAILED](state, error) {
      state.error = {
        code: error.status,
        type: "registration-new-customer"
      };
      state.isOrderPending = false;
    },
    [UPDATE_CUSTOMER.INITIAL](state) {
      // state.isOrderPending = true
    },
    [UPDATE_CUSTOMER.FAILED](state, error) {
      state.error = {
        code: error.status,
        type: "updating-customer"
      };
      state.isOrderPending = false;
    },
    [ADD_CUSTOMER_TO_ORDER.SUCCEEDED]() {
      // console.log('add customer')
    },
    [REMOVE_CUSTOMER_FROM_ORDER.INITIAL](state) {
      console.log("remove customer init");
    },
    [REMOVE_CUSTOMER_FROM_ORDER.SUCCEEDED](state, result) {
      console.log("remove customer", result);
    },
    [REMOVE_CUSTOMER_FROM_ORDER.FAILED](state, error) {
      console.error(error);
    },

    [GET_SPECIAL_ASSISTANCE.SUCCEEDED](
      state,
      {
        type,
        result
      }: {
                type: string;
                result: any;
            }
    ) {
      state.typesOfAssistance[type] = result.data as string[];
    },
    [GET_SPECIAL_ASSISTANCE.FAILED](state, error) {
      state.error = {
        code: error.status,
        type: "get-special-assistance"
      };
      state.isOrderPending = false;
    },
    [GET_ORDER_PRERESERVATION_STATUS.SUCCEEDED](state, response) {
      state.orderPrereservationStatus = response.data.status;
    },
    [GET_ORDER_PRERESERVATION_STATUS.FAILED](state, error) {
      state.error = {
        code: error.status,
        type: "get-order-prereservation"
      };
    },
    [START_ORDER_RESERVATION.SUCCEEDED](state, response) {
      state.orderNumber = response.data.orderNumber;
    },
    [START_ORDER_RESERVATION.FAILED](state, error) {
      state.error = {
        code: error.status,
        type: "start-order-reservation"
      };
      state.isOrderPending = false;
    },
    [GET_RESERVATION_STATUS.SUCCEEDED](state, result: any) {
      state.orderStatus = result.data.status;
      state.isOrderPending = true;
    },
    [GET_ISSUE_TICKET_STATUS.INITIAL](state) {
      state.issueStatusPending = true;
    },
    [GET_ISSUE_TICKET_STATUS.SUCCEEDED](state, result: any) {
      state.issueStatus = result.status;
      state.issueStatusPending = false;
    },
    [GET_ISSUE_TICKET_STATUS.FAILED](state, error) {
      console.error(error);
      state.issueStatus = null;
      state.issueStatusPending = false;
    },
    [GET_TICKET_ALLOWANCE.INITIAL](state) {
      state.ticketingAllowanceStatus = null;
      state.ticketingAllowancePending = true;
    },
    [GET_TICKET_ALLOWANCE.SUCCEEDED](state, result: any) {
      state.ticketingAllowanceStatus = result;
      state.ticketingAllowancePending = false;
    },
    [GET_TICKET_ALLOWANCE.FAILED](state, error) {
      console.error(error);
      state.ticketingAllowanceStatus = null;
      state.ticketingAllowancePending = true;
    },
    [GET_CONFIRMATION_STATUS.INITIAL](state) {
      state.conformationStatusPending = true;
    },
    [GET_CONFIRMATION_STATUS.SUCCEEDED](
      state,
      result: {
                status: "OK" | "AWAIT" | "ERROR"
            }
    ) {
      state.conformationStatus = result.status;
      state.conformationStatusPending = false;
    },
    [GET_CONFIRMATION_STATUS.FAILED](state, error) {
      console.error(error);
      state.conformationStatus = null;
      state.conformationStatusPending = false;
    },
    [GET_FLIGHT_ORDER_ITEMS.SUCCEEDED](state: IFlyOrderState, response: OrderItemResource[]) {
      state.order = response.data[0];
      if (response.data[0] && response.data[0].reservation) {
        state.pnrNumber = response.data[0].reservation.reservationNumbers.PNR;
      }
      if (response.data[0] && response.data[0].entityId) {
        state.itemId = response.data[0].entityId;
      }
    },
    [GET_FLIGHT_ORDER_ITEMS.FAILED](state, error) {
      state.error = {
        code: error.status,
        type: "get-flight-order-items"
      };
    },
    [GET_DRAFT_ORDER.SUCCEEDED](state, response) {
      state.order = response.data[0];
    },
    [GET_DRAFT_ORDER.FAILED](state, error) {
      state.error = {
        code: error.status,
        type: "get-flight-order-items"
      };
    },
    [SERVICE_RESERVATION.INITIAL](state) {
      state.isServiceReservationError = null;
    },
    [SERVICE_RESERVATION.SUCCEEDED](state) {
      state.isServiceReservationError = null;
    },
    [SERVICE_RESERVATION.FAILED](state, error) {
      state.isServiceReservationError = error;
    },
    [SERVICE_FLIGHT_DETAILS.INITIAL](state) {
      state.isServiceFlightReservationError = null;
    },
    [SERVICE_FLIGHT_DETAILS.SUCCEEDED](state) {
      state.isServiceFlightReservationError = null;
    },
    [SERVICE_FLIGHT_DETAILS.FAILED](state, error) {
      state.isServiceFlightReservationError = error;
    },
    [TRAVEL_ORDER_RESOURCE.INITIAL](state) {
      state.travelOrderResourcePending = true;
      state.travelOrderResourceError = null;
      state.travelOrderResource = null;
    },
    [TRAVEL_ORDER_RESOURCE.SUCCEEDED](state, res) {
      state.travelOrderResource = res.data;
      state.travelOrderResourcePending = false;
    },
    [TRAVEL_ORDER_RESOURCE.FAILED](state, error) {
      state.travelOrderResourceError = error;
      state.travelOrderResourcePending = false;
    },
    [GET_PAYMENT_URL.SUCCEEDED](state, paymentUrl: string) {
      state.paymentUrl = paymentUrl;
    },
    [GET_PAYMENT_URL.FAILED](state, error) {
      state.error = error;
    },
    [CLEAR_PAYMENT_URL](state) {
      state.paymentUrl = null;
    },
    [GET_AGENCY_CUSTOMERS.INITIAL](state) {
      state.agencyCustomersPending = true;
    },
    [GET_AGENCY_CUSTOMERS.SUCCEEDED](
      state,
      response: AxiosResponse<{
                content: ICustomer[]
            }>
    ) {
      state.agencyCustomers = response.data.content;
      state.agencyCustomersPending = false;
    },
    [GET_AGENCY_CUSTOMERS.FAILED](state) {
      state.agencyCustomers = [];
      state.agencyCustomersPending = false;
    },
    [SET_REMARKS.INITIAL](state) {
      state.remarksError = null;
    },
    [SET_REMARKS.SUCCEEDED](state) {
      state.remarksError = null;
    },
    [SET_REMARKS.FAILED](state, error) {
      state.remarksError = null;
      state.remarksError = error;
    }
  }
});
